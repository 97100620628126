import handlerFetch from './fetch'
import axiosUpload from './fetchUpload'
import { createQueryParamsString } from './utils'
import { AxiosProgressEvent } from 'axios'

export const apiGetList = async <T>(url: string, { offset = 0, limit = null }: Api.Params.Pagination) => {
  const params = createQueryParamsString({ offset, limit })
  return await handlerFetch<T>(`${url}/${params}`, null, true)
}

export const apiGetListWithParams = async <T>(
  url: string,
  params: Api.Params.Pagination | Api.Params.Statistic | Api.Params.Voice = {}
) => {
  const queryParams = createQueryParamsString(params)
  return await handlerFetch<T>(`${url}/${queryParams}`, null, true)
}

export const apiGetOne = async (url: string, id?: number, signal?: AbortSignal) => {
  //Добавлено из-за запроса /translate/id/status
  if (id) {
    return await handlerFetch<any>(`${url}/${id.toString()}`, null, true)
  } else {
    return await handlerFetch<any>(`${url}`, null, true, 2, false, signal)
  }
}

export const apiGetBlob = async (url: string, signal?: AbortSignal) => {
  return await handlerFetch<any>(`${url}`, null, true, 2, true, signal)
}

export const apiUpdate = async <T>(url: string, id?: number, payload?: any) => {
  return await handlerFetch<T>(
    url + (id ? '/' + id : ''),
    {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    true
  )
}

export const apiCreate = async <T>(url: string, payload: any) => {
  return await handlerFetch<T>(
    url + '/',
    {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    true
  )
}
export const apiCreateWithoutSlash = async <T>(url: string, payload: any) => {
  return await handlerFetch<T>(
    url,
    {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    true
  )
}
export const apiUpload = async <T>(
  url: string,
  payload: any,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) => {
  return await axiosUpload<T>(
    url + '/',
    {
      method: 'POST',
      data: payload,
      headers: {}
    },
    true,
    undefined,
    onUploadProgress
  )
}

export const apiDelete = async (url: string, id: number) => {
  return await handlerFetch<Api.Response.Success>(
    url + '/' + id,
    {
      method: 'DELETE'
    },
    true
  )
}

export const apiCount = async (url: string, onlyAdmin?: boolean) => {
  return await handlerFetch<number>(url + `/count/${onlyAdmin ? '?my=true' : ''}`, null, true)
}
