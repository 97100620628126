import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Switch, Select, Typography, Space, Button, Divider, Flex, Tooltip } from 'antd'
import { Link, useSearchParams } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form'
import { Image } from 'antd/lib'
import VoiceList from './VoiceList'
import { languageOptions as LanguageList } from '../../../utils/voices_list'
import { apiGetList } from '../../../api/crud'

const marginBottomStyle = {
  marginBottom: '0px'
}
const field = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10
}
interface VideoSettingsFormProps {
  form: FormInstance
  videoSettings: {
    language: string
    save_origin_voice: boolean
    has_logo: boolean
    notification: boolean
    voice_clone: boolean
    voice_gender: string
    lipsync: boolean
    subtitle_download: boolean
    subtitle_on_video: boolean
    subtitle_edit: boolean
    voice_count: number
  }
  handleSave: (values: any) => void
  price: Api.Response.VideoPrice | null
  user: Api.Response.User
  error: {
    notEnoughMoney: boolean
  }
  info: Api.Response.VideoTranslate | null
  startTranslate: () => void
  disabledLogo: boolean
}

const VideoSettingsForm: React.FC<VideoSettingsFormProps> = ({
  form,
  videoSettings,
  handleSave,
  startTranslate,
  user,
  error,
  price
}) => {
  const handleVoiceChange = (voice: string | null) => {
    form.setFieldsValue({ voice_gender: voice })
    handleSave({ voice_gender: voice })
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const [language, setLanguage] = useState<Api.Response.Language[]>([])
  const [languageOptions, setLanguageOptions] = useState<{ value: string; label: React.ReactNode; icon: string }[]>([])
  const [currentLang, setCurrentLang] = useState<{ value: string; label: React.ReactNode; icon: string }>(
    languageOptions[0]
  )
  const isRestart = searchParams.get('restart')
  const deleteRestartParams = () => {
    if (isRestart === 'true') {
      searchParams.delete('restart')
      setSearchParams(searchParams)
    }
  }
  const getLanguages = async () => {
    const res = await apiGetList<Api.Response.Language[]>('translate/get_languages', { offset: 0, limit: 100 })
    if (res.isError) {
      console.error(res.data)
      return
    } else {
      return res.data
    }
  }
  console.log(currentLang)
  useEffect(() => {
    getLanguages().then(data => data && setLanguage(data))
  }, [])
  useEffect(() => {
    setLanguageOptions(language.map(item => ({ value: item.language, label: item.language, icon: item.icon_url })))
  }, [language])
  useEffect(() => {
    setCurrentLang(languageOptions[0])
  }, [languageOptions])
  // useEffect(() => {
  //   if (videoSettings.voice_clone) {
  //     form.setFieldsValue({ voice_gender: null })
  //     handleSave({ voice_gender: null })
  //   }
  // }, [videoSettings.voice_clone, form])
  // useEffect(() => {
  //   if (videoSettings.language !== 'en') {
  //     form.setFieldsValue({ voice_clone: false })
  //     handleSave({ voice_clone: false })
  //   }
  // }, [videoSettings.language, form])
  return (
    <Form form={form} onValuesChange={handleSave} onFinish={() => startTranslate()} style={{ padding: 18 }}>
      <Row
        align='stretch'
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 }
        ]}
      >
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Divider orientation='left'>Видео</Divider>
          <Flex vertical gap={20}>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='has_logo' valuePropName='checked' style={{ margin: 0 }}>
                <Tooltip title={price?.plan.name === 'Демо' ? 'Отключение доступно с тарифа "Профессиональный"' : ''}>
                  <Switch defaultValue={videoSettings.has_logo} disabled={price?.plan.name === 'Демо'} />
                </Tooltip>
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Водяной знак на видео</Typography.Paragraph>
            </Space>

            <Space style={{ alignItems: 'center' }}>
              <Form.Item name='lipsync' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.lipsync} style={{ margin: 0 }} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Липсинк</Typography.Paragraph>
            </Space>
          </Flex>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Divider orientation='left'>Субтитры</Divider>
          <Flex vertical gap={20}>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='subtitle_on_video' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.subtitle_on_video} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Наложение субтитров</Typography.Paragraph>
            </Space>

            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='subtitle_edit' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.subtitle_edit} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Редактирование субтитров</Typography.Paragraph>
            </Space>
            {/* <Space style={{ alignContent: 'center' }}>
              <Form.Item name='subtitle_download' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.subtitle_download} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>Скачивание субтитров</Typography.Paragraph>
            </Space> */}
          </Flex>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Divider orientation='left'>Аудио</Divider>

          <Flex vertical gap={20}>
            <Typography.Text style={{ marginBottom: '-20px' }}>Язык перевода</Typography.Text>
            <Form.Item name='language' style={{ marginBottom: '0px' }}>
              <Select
                defaultValue='en'
                style={{ maxWidth: '320px' }}
                onChange={value =>
                  setCurrentLang(languageOptions.find(option => option.value === value) || languageOptions[0])
                }
                options={languageOptions.map(option => ({
                  value: option.value,
                  label: (
                    <>
                      <Image src={option.icon} className='icons_flag' preview={false} width={20} />{' '}
                      {LanguageList.find(item => item.value === option.value)?.label}
                    </>
                  )
                }))}
              />
            </Form.Item>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='save_origin_voice' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.save_origin_voice} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>
                Сохранить оригинальную приглушенную речь
              </Typography.Paragraph>
            </Space>
            {
              <Space style={{ alignContent: 'center' }}>
                <Form.Item name='voice_clone' valuePropName='checked' style={{ margin: 0 }}>
                  <Switch defaultValue={videoSettings.voice_clone} />
                </Form.Item>
                <Typography.Paragraph style={marginBottomStyle}>Клонировать голос</Typography.Paragraph>
              </Space>
            }
            {/* Скрываем если выбрано - клонировать голос */}
            <div className={videoSettings.voice_clone ? 'hidden' : ''}>
              <div style={{ maxWidth: '320px' }}>
                <Divider plain style={{ marginTop: 0 }}>
                  или выберите голос
                </Divider>
              </div>
              {/* Выбор спикера */}
              <Form.Item name='voice_gender' style={{ margin: 0 }}>
                <VoiceList onVoiceChange={handleVoiceChange} lang={currentLang?.value} />
              </Form.Item>
            </div>
          </Flex>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Divider orientation='left'>Другое</Divider>
          <Flex vertical gap={20}>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item name='notification' valuePropName='checked' style={{ margin: 0 }}>
                <Switch defaultValue={videoSettings.notification} disabled={!user.telegram_chatid} />
              </Form.Item>
              <Typography.Paragraph style={marginBottomStyle}>
                Получить уведомление
                {(user.telegram === null || user.telegram === '') && (
                  <small style={{ color: 'red', display: 'block' }}>
                    <Typography.Paragraph style={marginBottomStyle}>
                      <Link to={`/app/profile?path=${window.location.pathname}`}>Укажите Telegram ID</Link>
                    </Typography.Paragraph>
                  </small>
                )}
                {(user.telegram_chatid === null || user.telegram_chatid === '') && (
                  <small style={{ color: 'red', display: 'block' }}>
                    <Typography.Paragraph style={marginBottomStyle}>
                      <a href='https://t.me/voicecover_noty_bot' target='_blank' rel='noreferrer'>
                        Запустите бота
                      </a>
                    </Typography.Paragraph>
                  </small>
                )}
              </Typography.Paragraph>
            </Space>
            <Space style={{ alignContent: 'center' }}>
              <Form.Item
                name='voice_count'
                style={{ margin: 0 }}
                rules={[{ required: true, message: 'Пожалуйста, выберите количество спикеров' }]}
                label='Количество спикеров'
                labelAlign='left'
              >
                <Select
                  style={{ marginLeft: '5px', minWidth: '70px' }}
                  placeholder='Выберите'
                  size='small'
                  defaultValue={field[1]}
                  options={Object.entries(field).map(([key, value]) => ({
                    value: parseInt(key),
                    label: value
                  }))}
                />
              </Form.Item>
            </Space>
          </Flex>
        </Col>
        <Col sm={{ span: 24 }}>
          <Form.Item name='userBalance' rules={[{ max: user?.balance }]}></Form.Item>
          <Flex align='flex-end' justify='flex-end' vertical>
            <Button
              type='primary'
              style={{ float: 'right' }}
              onClick={() => {
                deleteRestartParams()
                form.submit()
              }}
              disabled={error.notEnoughMoney}
              size='large'
            >
              Запустить перевод
            </Button>
            {error.notEnoughMoney && (
              <Link to='/app/balance' target='_blank' className='btn' style={{ float: 'right' }}>
                Пополнить баланс
              </Link>
            )}
          </Flex>
        </Col>
      </Row>
    </Form>
  )
}

export default VideoSettingsForm
